import { Head } from "@inertiajs/react";

export default function AppHead({
    title,
    children,
    clinic,
}: {
    title: string;
    children?: any;
    clinic?: any;
}) {
    const appName = clinic?.name ?? "Everflex";

    return (
        <Head>
            <title>{`${title} - ${appName}`}</title>
            {children}
        </Head>
    );
}
